import CGIcon from '../socials/coingecko';
import GithubIcon from '../socials/github';
import TelegramIcon from '../socials/telegram';
import TwitterIcon from '../socials/twitter';

export default function Footer() {
   return (
     <footer className="relative mx-4 flex flex-col items-center justify-between space-y-4 pb-8 pt-20 lg:container lg:mx-auto lg:flex-row lg:space-x-4 lg:space-y-0">
       <ul className="flex items-center space-x-4">
         {/* <li className="border-b-2 border-transparent hover:border-white">
           <a href="https://defiplaza.net/blog" target="_blank">
             Blog
           </a>
         </li> */}
         <li className="border-b-2 border-transparent hover:border-white">
           <a href="https://docs.defiplaza.net" target="_blank">
             Docs
           </a>
         </li>
         <li className="border-b-2 border-transparent hover:border-white">
           <a href="https://stats.defiplaza.net/pools/radixplaza" target="_blank">
             Analytics
           </a>
         </li>
       </ul>
       <div className="flex items-center space-x-4">
         <p className="hover:text-primary-100">
           <a href="https://t.me/defiplaza" target="_blank">
             <TelegramIcon />
           </a>
         </p>
         <p className="hover:text-primary-100">
           <a href="https://twitter.com/defiplaza" target="_blank">
             <TwitterIcon />
           </a>
         </p>
         <p className="hover:text-primary-100">
           <a href="https://github.com/OMegaSyndicate" target="_blank">
             <GithubIcon />
           </a>
         </p>
         <p className="hover:text-primary-100">
           <a href="https://www.coingecko.com/en/coins/defiplaza" target="_blank">
             <CGIcon />
           </a>
         </p>
       </div>
     </footer>
   );
}
